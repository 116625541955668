import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import { graphql, useStaticQuery } from "gatsby";

import SEO from "../components/seo";
import Text from "../components/text";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import Introduction from "../components/introduction";

const Zauberflasche = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      zauberflasche1: file(name: { eq: "zauberflasche_01" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zauberflasche2: file(name: { eq: "zauberflasche_02" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout>
        <SEO title="Zauberflasche" />
        <Title text="Zauberflasche" />
        <Introduction>
          {intl.formatMessage({ id: "zauber.intro_1" })}
        </Introduction>
        <Text>
          {intl.formatMessage({ id: "zauber.text_1" })}
          <a href="/salive">sALIVE</a>
          {intl.formatMessage({ id: "zauber.text_2" })}
        </Text>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, 1fr);
            grid-gap: 20px;
            margin-top: 20px;
          `}
        >
          <Img
            fluid={data.zauberflasche1.childImageSharp.fluid}
            key={data.zauberflasche1.name}
            alt={data.zauberflasche1.name}
          />
          <Img
            fluid={data.zauberflasche2.childImageSharp.fluid}
            key={data.zauberflasche2.name}
            alt={data.zauberflasche2.name}
          />
        </div>
        <Return />
      </Layout>
    </>
  );
};

export default Zauberflasche;
